import http from "../http-common-login";
import axios from "axios";

class AuthDataService {
  register(user) {
    return http.post("/register_v2", user);
  }

  authenticate(user) {
    return http.post(`/login_matrix`, user);

   // return http.post(`/admin_login_matrix`, user);
  }
  verify(user){
    return http.post(`/verify_token`, user);

  }
}
export default new AuthDataService();