import React, { useEffect,useRef,useState } from "react";
import Button from "@mui/material/Button";
import Snackbar from "@mui/material/Snackbar";
import { Alert, AlertTitle } from "@mui/material";
import { useGlobalContext } from "../../ContextGlobal/ContectGloval";
import textMessage from "../../../assets/ringtones/ringtone_call_phone.mp3";

const RightChatWindowHeader = (props) => {
    const {
        name,
        selectRoomID,
        cancelAudioCall,
        cancelVideoCall,
        timeoutAudioCall,
        timeoutVideoCall
    } = props;
    const context = useGlobalContext();
    let userId = localStorage.getItem("mxvr_user_id");
 



    const handleClose = (reason) => {
        if (reason === "clickaway") {
            return;
        }

        context.setOpen(false);
    };

    const handleCloseIncomingCall = (reason) => {
        if (reason === "clickaway") {
            return;
        }
        context.setOpenIncomingCall(false);
    };

    const AcceptCall = () => {
        context.setHideRejectAccept(false);

        TimeoutCall();
        context.setAcceptCallerName(`${localStorage.getItem("mxvr_user_id")}kuumba.videocall`)

        if (context.videoCallStart === true) {

            context.setNewVideo(false);
            context.setVideoCallStart(false);
            context.setCreateVideo(false);
            document.getElementById("autoHangupCall").click();

            context.setRoomId(context.incomingCall);
            context.setStartCall(context.incomingCall);
            context.setOpenIncomingCall(false);

            context.setNewVideo(true);
            context.setVideoCallStart(true);
            context.setCreateVideo(true);
        } else {
            context.setNewVideo(true);
            context.setVideoCallStart(true);
            context.setCreateVideo(true);
            context.setRoomId(context.incomingCall);
            context.setStartCall(context.incomingCall);
            context.setOpenIncomingCall(false);
        }
        // selectRoomID(context.incomingCall)
    };

    const RejectCall = () => {
      //  console.log("reject_call");

        context.setHideRejectAccept(false)

        context.setOpenIncomingCall(false);
        context.setIncomingCall("");
TimeoutCall()
        cancelVideoCall(context.incomingCall, userId);
       
    };
    const audioRef = useRef(null);
    const [autoplayAllowed, setAutoplayAllowed] = useState(true); // Set to true initially
    const playAudio = () => {
        if (autoplayAllowed&&context.settingTune.callTune) {  
                  audioRef.current?.play().catch(error => {
                // Handle autoplay error, if needed
                console.error('Autoplay failed:', error);
                  
            });
        }
        }
        useEffect(() => {
            if(context.openIncomingCall)
            {
            playAudio()
            }
        }, [context.openIncomingCall]);
        
    useEffect(() => {
      const handleUserInteraction = () => {
        setAutoplayAllowed(true);
      };
    console.log("handleUserInteraction",autoplayAllowed)
      document.addEventListener('click', handleUserInteraction);
    
      return () => {
        document.removeEventListener('click', handleUserInteraction);
      };
    }, []);
    
    const TimeoutCall = () => {

        setTimeout(() => {
            context.setOpenIncomingCall(false);
            context.setIncomingCall("");
            context.setHideRejectAccept(true)

        }, 30000);
    };



    useEffect(() => {
        TimeoutCall();
        
    }, [context.incomingCall]);

    return (
        <>
            <Snackbar
                // open={context.open}
                //autoHideDuratiab_abccccon={6000}
                onClose={handleClose}
                message="Note archived"
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "right"
                }}
            // action={action}
            >
                <Alert
                    onClose={handleClose}
                    severity="info"
                    sx={{ width: "100%" }}
                >
                    {context.count} New Messages
                </Alert>
            </Snackbar>
<div></div>
            <Snackbar
            style={{display:"flex",justifyContent:"center",top:"0px",left:"0px",bottom:"0px",right:"0px"}}
                open={context.openIncomingCall}
                autoHideDuration={6000}
                onClose={handleCloseIncomingCall}
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "right"
                }}
            >


<div class="card">
  <div class="header">
    <div class="animation">
      <span class="icon ring"></span>
      <div class="cercle one"></div>
      <div class="cercle two"></div>
      <div class="cercle three"></div>
    </div>

    <p class="phoneNumber"> {context?.groupName}</p>
    <p class="calling">Calling</p>
  </div>

  <div class="footer">
    <div class="bouton raccrocher">
      <span  onClick={RejectCall} class="icon red"></span>
    </div>
    <div class="bouton decrocher">
      <span  onClick={AcceptCall} class="icon green"></span>
    </div>
    <audio ref={audioRef}  muted={!autoplayAllowed}>
          <source src={textMessage} type="audio/mpeg" />
          Your browser does not support the audio tag.
        </audio>
  
  </div>
	</div>
                {/* <div className="callSnackbar">
                    <div>

<p>Call in {context?.groupName}</p>

                    </div>
                    <button
                        className="btn btn-danger btn-circle mr-half"
                        onClick={RejectCall}
                    >
                          
                        <i
                            className="fa fa-phone callIconSize"
                            style={{ transform: "rotate(135deg)" }}
                        ></i>
                      
                    </button>

                    <button
                        className="btn btn-success btn-circle"
                        onClick={AcceptCall}
                    >
                        <i className="fa fa-phone callIconSize"></i>
                    </button>
                </div> */}
            </Snackbar>
            <div className="header-chat-info row m-0">
                <div className="col-7">
                    <label className="d-flex align-items-center">
                        <i className="fa fa-th-large text-white me-4 font-20"></i>{" "}
                        Chatting with {name}
                    </label>
                </div>
                <div className="col-5">
                    <div className="group-section-chat d-flex align-items-center">
                        <i className="fa fa-group me-3"></i>
                        <div className="dropdown me-5">
                            <strong
                                className="dropdown-toggle"
                                type="button"
                                id="dropdownMenuButton1"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                            >
                                4
                            </strong>
                            <ul
                                className="dropdown-menu"
                                aria-labelledby="dropdownMenuButton1"
                            >
                                <li>
                                    <a className="dropdown-item" href="#">
                                        Arslan Mahmood
                                    </a>
                                </li>
                                <li>
                                    <a className="dropdown-item" href="#">
                                        Safi Raza
                                    </a>
                                </li>
                                <li>
                                    <a className="dropdown-item" href="#">
                                        Adeel Salman
                                    </a>
                                </li>
                                <li>
                                    <a className="dropdown-item" href="#">
                                        Usman Khalid
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <i className="fa fa-bell-o font-20"></i>
                    </div>
                </div>
            </div>
        </>
    );
};

export default RightChatWindowHeader;
