import { SwipeableDrawer ,makeStyles} from "@material-ui/core";
import React from "react";

function ReusableSwipeDrawer({open,toggleDrawer,children,width}) {
const useStyles = makeStyles((theme) => ({
        drawerPaper: {
          width: width, // Set the desired width here
        },
      }));
      const classes = useStyles();
      
  return <div>
  
  
  <SwipeableDrawer
            anchor={"right"}
            open={open}
            onClose={toggleDrawer(false)}
            onOpen={toggleDrawer(true)}
            classes={{ paper: classes.drawerPaper }} // Apply custom styles here
        >
        {children}
        
        </SwipeableDrawer>  </div>;
}

export default ReusableSwipeDrawer;
