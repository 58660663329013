import React, { useState } from "react";
import { searchUserCreateRoom, userSearch } from "../../../actions/desktop";
import { useDispatch, useSelector } from "react-redux";
import { Modal, Button } from "react-bootstrap";
import placeholder2 from "../../../assets/images/placeholder2.png";

const InviteUser = (props) => {
    const { setOpen, inviteUserListCallBack, roomInfo } = props;
    const [invitedUser, setInvitedUser] = useState([]);
    const [groupName, setGroupName] = useState("");

    const [invitedUserFun, setInvitedUserFun] = useState([]);
    const [search, setSearch] = useState("");
    const dispatch = useDispatch();

    const handleClose = () => {
        setOpen(false);
    };

    const handleSearch = (e) => {
        setSearch(e.target.value);
        // if (e.key === 'Enter') {
        const valueFromUser = e.target.value;
        dispatch(searchUserCreateRoom(valueFromUser));
        dispatch(userSearch(valueFromUser));
        // }
    };

    const users = useSelector((state) => {
        return state.desktop.searchUser;
    });
    //for testing case only ahmad
    const testingCase = useSelector((state) => {
        return state.desktop.testingCase;
    });

    const handleCreateGroup = () => {
        if (invitedUserFun.length !== 0) {
            const newUsers = invitedUserFun.map((user) => ({
                ...user,
                matrix_user_id: user.name
            }));
            let options = {
                visibility: "private",
                invite: invitedUserFun
            };

            console.log("newUsers", options);
            inviteUserListCallBack(options);
            //// console.log("optioncalled",options)
            // createGroupCallBack(options);
        } else {
            alert("User not selected!");
        }
    };

    // console.log("testingCase",testingCase)

    const handleCheckUser = (user, e) => {
        console.log("usersss", user);
        // if(user.matrix_user_id == null && user.matrix_user_id == "")
        // return ;
        if (e.target.checked) {
            // if (user.matrix_user_id != null && user.matrix_user_id !== "") {
            if (user.name != null && user.name !== "") {
                setInvitedUserFun((invitedUser) => [...invitedUser, user]);

                // if (invitedUser.includes(user.matrix_user_id)) {
                //     let filteredArray = invitedUser.filter(function (e) {
                //         return e !== user.matrix_user_id;
                //     });
                //     setInvitedUser(filteredArray);
                //     let filteredArrayFun = invitedUserFun.filter(function (e) {
                //         return e.matrix_user_id !== user.matrix_user_id;
                //     });
                //     setInvitedUserFun(filteredArrayFun);
                // } else {
                //     setInvitedUser((invitedUser) => [
                //         ...invitedUser,
                //         user.matrix_user_id
                //     ]);
                //     setInvitedUserFun((invitedUser) => [...invitedUser, user]);
                // }
            }
        } else {
            setInvitedUserFun(
                invitedUserFun.filter(
                    (item) => item?.displayname !== e.target.value
                )
            );

            // console.log("calledui", invitedUserFun?.user_name);
        }
    };
    const removeUser = (user) => {
        setInvitedUserFun(
            invitedUserFun.filter((item) => item?.name !== user?.name)
        );

        // if (invitedUser.includes(user.matrix_user_id)) {
        //     let filteredArray = invitedUser.filter(function (e) {
        //         return e !== user.matrix_user_id;
        //     });
        //     setInvitedUser(filteredArray);
        //     let filteredArrayFun = invitedUserFun.filter(function (e) {
        //         return e.matrix_user_id !== user.matrix_user_id;
        //     });
        //     setInvitedUserFun(filteredArrayFun);
        // }
    };
    const checkedFun = (user) => {
        return Boolean(
            invitedUserFun.find((item) => {
                return item?.name == user.name;
            })
        );
    };

    return (
        <div id="myModal" className="modalCustom">
            <div className="modalCustom-content">
                <div className="modal-header">
                    <div
                        style={{ fontSize: "24px", fontFamily: "Manrope" }}
                        className="modal-title h4"
                    >
                        Invite User{" "}
                    </div>
                    <button
                        type="button"
                        className="btn-close"
                        aria-label="Close"
                        onClick={handleClose}
                    ></button>
                </div>
                <>
                    <p className="paragraphGroup">
                        Start a conversation with someone using their name,
                        email address or username.
                    </p>
                    {/* (like @user1:devm.vjoinlife.com) */}
                    <div className="form-outline">
                        <div>
                            <div
                                className="mt-2"
                                style={{ display: "flex", flexWrap: "wrap" }}
                            >
                                {invitedUserFun.map((user, i) => (
                                    <>
                                        <div className="group-member" key={i}>
                                            <div className="img-sec me-2">
                                                <img
                                                    style={{
                                                        width: "25px",
                                                        height: "25px"
                                                    }}
                                                    src={placeholder2}
                                                    className="rounded-circle"
                                                    alt="Avatar"
                                                />
                                            </div>
                                            <div style={{ color: "#3b4a54" }}>
                                                {user.displayname}
                                                {/* <div className="">
                                        {user.user_email}
                                    </div> */}
                                            </div>
                                            <div>
                                                <i
                                                    style={{
                                                        paddingLeft: "13px",
                                                        color: "#8696a0"
                                                    }}
                                                    className="fa ml-2 fa-close "
                                                    onClick={() =>
                                                        removeUser(user)
                                                    }
                                                ></i>
                                            </div>
                                        </div>
                                    </>
                                ))}
                            </div>
                            <input
                                type="search"
                                placeholder="Search user"
                                className="form-control mt-2"
                                // onKeyDown={handleSearch}
                                onChange={handleSearch}
                            />
                        </div>
                    </div>
                    <div
                        className="list-group vh-30 d-block"
                        style={{ zIndex: 1 }}
                    >
                        {search &&
                            (users?.length > 0
                                ? users.map((user, i) =>
                                      user.matrix_user_id === null ? (
                                          ""
                                      ) : (
                                          <div key={i}>
                                              <label className="list-group-item listItemBackground">
                                                  <input
                                                      className="form-check-input me-1"
                                                      type="checkbox"
                                                      value={user?.displayname}
                                                      checked={checkedFun(user)}
                                                      onChange={(e) =>
                                                          handleCheckUser(
                                                              user,
                                                              e
                                                          )
                                                      }
                                                  />
                                                  {user.displayname}
                                                  <div className="ms-2 me-auto fw-normal">
                                                      {user.name}
                                                  </div>
                                              </label>
                                          </div>
                                      )
                                  )
                                : "")}
                    </div>
                </>
                <div
                    style={{ marginTop: "10px" }}
                    className="d-flex justify-content-end"
                >
                    <Button
                        style={{ width: "150px" }}
                        variant="primary"
                        onClick={handleCreateGroup}
                    >
                        Invite
                    </Button>
                    {/* <button className="btn btn-primary m-1" onClick={}></button> */}
                    {/* <button className="btn btn-secondary m-1" onClick={handleClose}>Close</button> */}
                </div>
            </div>
        </div>
    );
};

export default InviteUser;
