import React, { useEffect, useState } from "react";
import LeftPaneListItem from "./LeftPaneListItem";
import NewChat from "./NewChat";
import NewGroup from "./NewGroup";
import ExistingRoomJoin from "./ExistingRoomJoin";
import { useGlobalContext } from "../../ContextGlobal/ContectGloval";
import Search from "../../commen/search";
import moment from "moment";
import { RiAddLine } from "react-icons/ri";
import "./leftPaneCss/leftPane.css";
import { useRef } from "react";
import { selectRoom } from "../../../actions/desktop";
import { useDispatch } from "react-redux";

const LeftPane = (props) => {
    const [newChatOpen, setNewChatOpen] = useState(false);
    const [newGroupOpen, setNewGroupOpen] = useState(false);
    const [searchName, setSearchName] = useState();
    const [forFilter, setFilter] = useState();
    const [dropDownActive, setDropDownActive] = useState(false);
    const [searchOpen, setSearchOpen] = useState(false);

    const context = useGlobalContext();

    const [existRoomJoinOpen, setExistRoomJoinOpen] = useState(false);
    let { rooms, createGroupCallBack, fetchUserDetails, getAvatarUrl } = props;
    const [roomName, setRoomName] = useState();
    const dropDownRef = useRef(null);
    const dispatch = useDispatch();
    const handleOutsideClick = (event) => {
        if (
            dropDownRef.current &&
            !dropDownRef.current.contains(event.target)
        ) {
            setDropDownActive(false);
        }
    };

    useEffect(() => {
        if (dropDownActive) {
            document.addEventListener("click", handleOutsideClick);
        } else {
            document.removeEventListener("click", handleOutsideClick);
        }

        return () => {
            document.removeEventListener("click", handleOutsideClick);
        };
    }, [dropDownActive]);

    // alert("546 rooms===="+JSON.stringify(rooms))
    let i = 0;
    console.log("563=============", createGroupCallBack);
    let localRoomsArray = [];
    if (roomName && roomName?.length > 0) {
        localRoomsArray = [...roomName];
    }
    useEffect(() => {
        setRoomName(rooms);
        context?.setGetRoom(rooms);
        setFilter(rooms);
    }, [rooms]);
    const filterTime = (data) => {
        let lastElement;
        if (data) {
            lastElement = data[data?.length - 1];
            const time = moment(lastElement?.origin_server_ts).format("hh:MM");

            // console.log("lastMessage", data);
            return time;
        }
    };
    const filterMessage = (data) => {
        // console.log("DATA FOR LAST MSG:", data)

        if (data?.length) {
            let lastElement = data[data?.length - 1];
            let a = data.find((ab) => {
                return (
                    ab.event.event_id ==
                    lastElement?.content?.["m.relates_to"]?.event?.event_id
                );
            });
            // console.log("roooms", a);

            let obj = {
                msgType: lastElement?.content?.msgtype,
                msgBody: lastElement?.content?.body,
                isRead: lastElement?.isRead,
                reaction: lastElement?.content?.["m.relates_to"]?.key,
                reactmsg: a
            };

            return obj;
        }
    };

    const onClickChange = () => {
        // alert("called")
        //// console.log("searchName",searchName?.length)
        if (searchName?.length == "0" || searchName?.length == undefined) {
            setSearchOpen(!searchOpen);
        }
    };
    const onHandleChange = (event) => {
        // Access input value
        const query = event.target.value;
        setSearchName(query);
        // Create copy of item list
        var updatedList = [...forFilter];
        // Include all elements which includes the search query
        updatedList = updatedList.filter((item) => {
            //// console.log("filter")
            return (
                item.name
                    .toString()
                    .toLowerCase()
                    .indexOf(query.toLowerCase()) > -1
            );
        });
        // Trigger render with updated values
        setRoomName(updatedList);

        // console.log("588aaaa ", updatedList);
    };
    console.log("roomName", roomName);
    return (
        <div className="lp_main">
            {/* <div className="">
                <LeftSearchPane setSearchName={onHandleChange} />

                <div className="d-flex justify-content-between text-white p-3">
                    <h4 style={{ color: "#d7d7d7" }}>Dashboard</h4>
                    <Dropdown>
                        <Dropdown.Toggle className="border-0 bg-transparent">
                            <i
                                style={{ color: "#d7d7d7" }}
                                className="fa fa-plus"
                            ></i>
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                            <Dropdown.Item onClick={() => setNewChatOpen(true)}>
                                New Chat
                            </Dropdown.Item>
                            <Dropdown.Item
                                onClick={() => setNewGroupOpen(true)}
                            >
                                New Group
                            </Dropdown.Item>
                            <Dropdown.Item onClick={() => setExistRoomJoinOpen(true)}>Join existing Room</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </div>
            </div> */}
            <div className="lpm_header">
                <p className="lpmh_msg_text">Message</p>

                <div
                    className="lpmh_btn_container"
                    // onClick={() => setNewGroupOpen(true)}
                    // onClick={() => setNewChatOpen(true)}
                    ref={dropDownRef}
                    onClick={() => setDropDownActive(!dropDownActive)}
                >
                    {/* <p className="lpmh_btn_text">New Chats</p> */}
                    <div
                        style={{ width: "24px", height: "24px" }}
                        className="lmph_btn_icon_container"
                    >
                        <svg
                            viewBox="0 0 24 24"
                            height="24"
                            width="24"
                            fill="none"
                        >
                            <path
                                d="M9.53277 12.9911H11.5086V14.9671C11.5086 15.3999 11.7634 15.8175 12.1762 15.9488C12.8608 16.1661 13.4909 15.6613 13.4909 15.009V12.9911H15.4672C15.9005 12.9911 16.3181 12.7358 16.449 12.3226C16.6659 11.6381 16.1606 11.0089 15.5086 11.0089H13.4909V9.03332C13.4909 8.60007 13.2361 8.18252 12.8233 8.05119C12.1391 7.83391 11.5086 8.33872 11.5086 8.991V11.0089H9.49088C8.83941 11.0089 8.33411 11.6381 8.55097 12.3226C8.68144 12.7358 9.09947 12.9911 9.53277 12.9911Z"
                                fill="currentColor"
                            ></path>
                            <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M0.944298 5.52617L2.99998 8.84848V17.3333C2.99998 18.8061 4.19389 20 5.66665 20H19.3333C20.8061 20 22 18.8061 22 17.3333V6.66667C22 5.19391 20.8061 4 19.3333 4H1.79468C1.01126 4 0.532088 4.85997 0.944298 5.52617ZM4.99998 8.27977V17.3333C4.99998 17.7015 5.29845 18 5.66665 18H19.3333C19.7015 18 20 17.7015 20 17.3333V6.66667C20 6.29848 19.7015 6 19.3333 6H3.58937L4.99998 8.27977Z"
                                fill="currentColor"
                            ></path>
                        </svg>
                        {/* <RiAddLine  className="lpmh_btn_icon" /> */}
                    </div>

                    {dropDownActive ? (
                        <div className="dropDown_main">
                            <p
                                className="dropDown_options"
                                onClick={() => {
                                    setNewGroupOpen(true);
                                    setNewChatOpen(true);
                                }}
                            >
                                New Chat
                            </p>
                            {/* <p className="dropDown_options" onClick={() =>{ setNewChatOpen(false)}}>
                                      Group
                                 </p> */}
                            <p
                                className="dropDown_options"
                                onClick={() => {
                                    context?.setConferenceOpen(true);
                                    dispatch(selectRoom(null));
                                    context.setNewClose(false);
                                    context.setSelectedRoom(null);
                                }}
                            >
                                Conferences
                            </p>
                        </div>
                    ) : null}
                </div>
            </div>

            <div className="lpm_searchBox">
                {/* onClickChange={onClickChange} */}
                <Search onHandleChange={onHandleChange} placeholder="Search" />
            </div>
            {searchOpen && (
                <div
                    style={{
                        background: "white",
                        height: "80%",
                        width: "100%",
                        borderRadius: "5px",
                        position: "absolute"
                    }}
                ></div>
            )}

            <div className=" members_section chat_members d-flex">
                <ul className="list-unstyled text-white">
                    {roomName &&
                        React.Children.toArray(
                            roomName.map((room, i) => {
                                // alert(unreadMessages.length)
                                // filterTime(room?.messages)
                                return (
                                    <LeftPaneListItem
                                        //key={i}
                                        index={i}
                                        room={room}
                                        members={props.rooms}
                                        text={room.name}
                                        name={room.name}
                                        time={filterTime(room?.messages)}
                                        lastMessage={props.rooms}
                                        // lastMessage={filterMessage(
                                        //     room?.messages
                                        // )}
                                    />
                                );
                            })
                        )}
                </ul>
                {/* {newChatOpen ? (
                    <NewChat
                        setNewChatOpen={setNewChatOpen}
                        newChatOpen={newChatOpen}
                    />
                ) : (
                    ""
                )} */}
                {newGroupOpen ? (
                    <NewGroup
                        setNewGroupOpen={setNewGroupOpen}
                        newGroupOpen={newGroupOpen}
                        createGroupCallBack={createGroupCallBack}
                        newChatOpen={newChatOpen}
                        fetchUserDetails={fetchUserDetails}
                        getAvatarUrl={getAvatarUrl}
                    />
                ) : (
                    ""
                )}
                {existRoomJoinOpen ? (
                    <ExistingRoomJoin
                        open={existRoomJoinOpen}
                        setOpen={setExistRoomJoinOpen}
                        rooms={roomName}
                    />
                ) : (
                    ""
                )}
            </div>
        </div>
    );
};

export default LeftPane;
