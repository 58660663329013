import React, { useState, useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import { searchUserCreateRoom } from "../../../actions/desktop";
import { useDispatch, useSelector } from "react-redux";
import placeholder2 from "../../../assets/images/placeholder2.png";
import { customAvator } from "../../../actions/types";
const NewGroup = (props) => {
    const {
        newGroupOpen,
        setNewGroupOpen,
        createGroupCallBack,
        newChatOpen,
        invite,
        fetchUserDetails,
        getAvatarUrl
    } = props;
    const [invitedUserFun, setInvitedUserFun] = useState([]);
    const [groupName, setGroupName] = useState("");
    const [search, setSearch] = useState("");
    const dispatch = useDispatch();

    const [avatars, setAvatars] = useState({});

    const handleClose = () => {
        setNewGroupOpen(false);
    };

    // const handleSearch = (e) => {
    //     setSearch(e.target.value);
    //     if (e.key === "Enter") {
    //         const valueFromUser = e.target.value;
    //         dispatch(searchUserCreateRoom(valueFromUser));
    //     }
    // };

    // ==========================================================================================
    const handleSearch = (e) => {
        setSearch(e.target.value);
        const valueFromUser = e.target.value;
        dispatch(searchUserCreateRoom(valueFromUser));
    };
    // ==========================================================================================

    const users = useSelector((state) => {
        // console.log("users state:", state.desktop.searchUser);
        return state.desktop.searchUser;
    });
    console.log("groupUser", users);

    const handleCheckUser = (user, e) => {
        console.log("usersss", user);
        // if(user.matrix_user_id == null && user.matrix_user_id == "")
        // return ;
        if (e.target.checked) {
            // if (user.matrix_user_id != null && user.matrix_user_id !== "") {
            if (user.name != null && user.name !== "") {
                setInvitedUserFun((invitedUser) => [...invitedUser, user]);

                // if (invitedUser.includes(user.matrix_user_id)) {
                //     let filteredArray = invitedUser.filter(function (e) {
                //         return e !== user.matrix_user_id;
                //     });
                //     setInvitedUser(filteredArray);
                //     let filteredArrayFun = invitedUserFun.filter(function (e) {
                //         return e.matrix_user_id !== user.matrix_user_id;
                //     });
                //     setInvitedUserFun(filteredArrayFun);
                // } else {
                //     setInvitedUser((invitedUser) => [
                //         ...invitedUser,
                //         user.matrix_user_id
                //     ]);
                //     setInvitedUserFun((invitedUser) => [...invitedUser, user]);
                // }
            }
        } else {
            setInvitedUserFun(
                invitedUserFun.filter(
                    (item) => item?.displayname !== e.target.value
                )
            );

            // console.log("calledui", invitedUserFun?.user_name);
        }
    };
    const removeUser = (user) => {
        setInvitedUserFun(
            invitedUserFun.filter((item) => item?.name !== user?.name)
        );

        // if (invitedUser.includes(user.matrix_user_id)) {
        //     let filteredArray = invitedUser.filter(function (e) {
        //         return e !== user.matrix_user_id;
        //     });
        //     setInvitedUser(filteredArray);
        //     let filteredArrayFun = invitedUserFun.filter(function (e) {
        //         return e.matrix_user_id !== user.matrix_user_id;
        //     });
        //     setInvitedUserFun(filteredArrayFun);
        // }
    };
    const getAvator = async (user) => {
        const url = await getAvatarUrl(user.name);
        if (url) {
            setAvatars((prev) => ({ ...prev, [user.name]: url }));
        }
    };

    useEffect(() => {
        if (users) {
            users.forEach((user) => {
                if (user.matrix_user_id !== null) {
                    getAvator(user);
                }
            });
        }
    }, [users]);

    const handleCreateGroup = () => {
        if (newChatOpen) {
            if (groupName == "") {
                if (invitedUserFun.length !== 0) {
                    const newUsers = invitedUserFun.map((user) => ({
                        ...user,
                        matrix_user_id: user.name
                    }));
                    let options = {
                        visibility: "private",
                        invite: invitedUserFun,
                        name: groupName
                    };

                    console.log("newUsers", options);
                    //// console.log("optioncalled",options)
                    createGroupCallBack(options);
                    setNewGroupOpen(false);
                } else {
                    alert("User not selected!");
                }
            } else {
                alert("Room name Empty!");
            }
        } else {
            if (groupName !== "") {
                if (invitedUserFun.length !== 0) {
                    const newUsers = invitedUserFun.map((user) => ({
                        ...user,
                        matrix_user_id: user.name
                    }));
                    let options = {
                        visibility: "private",
                        invite: invitedUserFun,
                        name: groupName
                    };

                    console.log("newUsers", options);
                    //// console.log("optioncalled",options)
                    createGroupCallBack(options);
                    setNewGroupOpen(false);
                } else {
                    alert("User not selected!");
                }
            } else {
                alert("Room name Empty!");
            }
        }
    };
    const checkedFun = (user) => {
        return Boolean(
            invitedUserFun.find((item) => {
                return item?.name == user.name;
            })
        );
    };

    return (
        <Modal size="md" show={newGroupOpen} onHide={handleClose} centered>
            <Modal.Header closeButton>
                <Modal.Title>
                    {newChatOpen ? "Create Chat" : "Create Group"}{" "}
                </Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <p className="paragraphGroup">
                    Start a conversation with someone using their name, email
                    address or username.
                </p>
                {/* (like @user1:devm.vjoinlife.com) */}
                <div className="form-outline">
                    {(!newChatOpen || invite) && (
                        <input
                            type="text"
                            placeholder="Name"
                            className="form-control mt-2"
                            onChange={(e) => setGroupName(e.target.value)}
                        />
                    )}
                    <div>
                        <div
                            className="mt-2"
                            style={{ display: "flex", flexWrap: "wrap" }}
                        >
                            {invitedUserFun.map((user, i) => (
                                <>
                                    <div className="group-member" key={i}>
                                        <div className="img-sec me-2">
                                            {customAvator(
                                                user.displayname,
                                                25,
                                                10
                                            )}
                                        </div>
                                        <div style={{ color: "#3b4a54" }}>
                                            {user.displayname}
                                            {/* <div className="">
                                        {user.user_email}
                                    </div> */}
                                        </div>
                                        <div>
                                            <i
                                                style={{
                                                    paddingLeft: "13px",
                                                    color: "#8696a0"
                                                }}
                                                className="fa ml-2 fa-close "
                                                onClick={() => removeUser(user)}
                                            ></i>
                                        </div>
                                    </div>
                                </>
                            ))}
                        </div>
                        <input
                            type="search"
                            placeholder="Search user"
                            className="form-control mt-2"
                            // onKeyDown={handleSearch}
                            onChange={handleSearch}
                        />
                    </div>
                </div>
                <div
                    className="list-group vh-30 d-block"
                    style={{ zIndex: 1, marginTop: "10px" }}
                >
                    {search &&
                        (users?.length > 0
                            ? users.map((user, i) =>
                                  user.matrix_user_id === null ? (
                                      ""
                                  ) : (
                                      <div key={user.matrix_user_id}>
                                          <div
                                              style={{
                                                  display: "flex",
                                                  alignItems: "center",
                                                  textAlign: "start"
                                              }}
                                              className="list-group-item listItemBackground"
                                          >
                                              <div>
                                                  <input
                                                      className="form-check-input me-1"
                                                      type="checkbox"
                                                      value={user?.displayname}
                                                      checked={checkedFun(user)}
                                                      onChange={(e) =>
                                                          handleCheckUser(
                                                              user,
                                                              e
                                                          )
                                                      }
                                                  />
                                              </div>
                                              <div
                                                  style={{
                                                      paddingLeft: "10px"
                                                  }}
                                              >
                                                  {avatars[user.name] ? (
                                                      <div className="img-sec me-2">
                                                          <img
                                                              style={{
                                                                  width: "40px",
                                                                  height: "40px"
                                                              }}
                                                              src={
                                                                  avatars[
                                                                      user.name
                                                                  ]
                                                              }
                                                              className="rounded-circle"
                                                              alt="Avatar"
                                                          />
                                                      </div>
                                                  ) : (
                                                      <div>
                                                          {customAvator(
                                                              user.displayname,
                                                              40,
                                                              15
                                                          )}
                                                      </div>
                                                  )}
                                              </div>
<div>
<div style={{fontSize:"18px"}}> {user.displayname}</div>
                                              <div style={{paddingLeft:"0",fontSize:"13px"}} className=" me-auto fw-normal">
                                                  {user.name}
                                              </div>
</div>
                                             
                                          </div>
                                      </div>
                                  )
                              )
                            : "")}
                </div>
            </Modal.Body>

            <Modal.Footer>
                <Button
                    style={{ width: "150px" }}
                    variant="primary"
                    onClick={handleCreateGroup}
                >
                    {newChatOpen ? "Create Chat" : "Create Group"}{" "}
                </Button>
                {/* <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button> */}
            </Modal.Footer>
        </Modal>
    );
};

export default NewGroup;
