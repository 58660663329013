import React, { useState } from "react";

import { useDispatch } from "react-redux";
import { selectRoom } from "../../../actions/desktop";
import "./RightChatInfo.css";
import ProfileEdit from "../../topBar/profileEdit";
const RightChatRoomInfo = (props) => {
    const {
        open,
        setOpen,
        roomInfo,
        setGroupName,
        setTopic,
        leaveRoomCallBack,
        kickUserCallBack,
        setRoomAvatarUrl,
        getRoomAvatarUrl,

        inviteUserCallBack,
        inviteUserListCallBack
    } = props;
    const [openInvite, setOpenInvite] = useState(false);
    const dispatch = useDispatch();

    const userName = (val) => {
        if (val?.includes("_")) {
            return val?.split(":")[0].split("@")[1].split("_")[0];
        }

        return val?.split(":")[0].split("@")[1];
    };
    let arr = Object.values(roomInfo.members)[0].name;

    console.log("check props of array:", { arr });

    const toggleDrawer = (open) => (event) => {
        if (
            event &&
            event.type === "keydown" &&
            (event.key === "Tab" || event.key === "Shift")
        ) {
            return;
        }
        setOpen(open);
    };

    const LeaveRoom = () => {
        // console.log("ABC_ABC_LEAVE_CLICKED");
        let roomId = roomInfo.roomId;
        leaveRoomCallBack(roomId);

        dispatch(selectRoom(""));
    };

    const KickUser = (member) => {
        // console.log("ABC_ABC_KICK_USER", member);
        kickUserCallBack(roomInfo, member, "");
    };

    return (
        <>
            {open && (
                <ProfileEdit
                    opens={open}
                    setGroupName={setGroupName}
                    setTopic={setTopic}
                    header={"Group Info"}
                    roomInfo={roomInfo}
                    setOpen={setOpen}
                    setAvatarUrl={setRoomAvatarUrl}
                    openInvite={openInvite}
                    leaveRoomCallBack={leaveRoomCallBack}
                    kickUserCallBack={kickUserCallBack}
                    inviteUserCallBack={inviteUserCallBack}
                    inviteUserListCallBack={inviteUserListCallBack}
                    getAvatarUrl={getRoomAvatarUrl}
                    userName={userName(localStorage.getItem("mxvr_user_id"))}
                />
            )}
        </>
        // <ReusableSwipeDrawer
        //    // anchor={"right"}
        //     open={open}
        //     width={426}
        //     toggleDrawer={(val)=>toggleDrawer(val)}
        //     //onClose={()=>toggleDrawer}
        //     //style={{ zIndex: 1, width: "426px" }}
        // >

        //     <div className="groupInfo_main">
        //         <div className="gim_header_top">
        //             <span className="fa fa-close close_icon" onClick={( ) => setOpen(false) } />
        //             <span className="ht_title"> Group info </span>
        //         </div>

        //         <div className="gim_img_cont">
        //             <div className="gim_ic_imgDiv">
        //                 <img
        //                 className="grp_img"
        //                 src="https://mdbcdn.b-cdn.net/img/new/avatars/2.webp"
        //                 // src= {grp}

        //                 />
        //                 <div className="change_img">
        //                     <span className="fa fa-solid fa-camera iu_icon" > </span>
        //                     <span className="ci_text"> Change group icon </span>
        //                 </div>
        //             </div>

        //             <div className="grp_details" >
        //                 <span className="grp_name"> {roomInfo.name}  </span>

        //                 <span className="grp_members_count"> {`Group - ${Object.keys(roomInfo.members).length} participants`}  </span>
        //             </div>

        //         </div>

        //         <div className="other_func_container">
        //             <div className="enc_div">
        //                 <div className="enc_icon_div">
        //                     <span className="fa fa-solid fa-lock enc_icon" ></span>
        //                 </div>
        //                 <div className="enc_desc">
        //                     <span className="enc_d_title"> Encryption </span>
        //                     <span className="enc_d_detail"> Messages are end-to-end encrypted. Click to learn more. </span>
        //                 </div>
        //             </div>
        //         </div>

        //         <div className="gim_membersList_cont">
        //             <div className="header_div">
        //                 <span className="member_count"> {`${Object.keys(roomInfo.members).length} participants`} </span>
        //                 <span className="fa fa-search"> </span>
        //             </div>
        //             <button onClick={()=>setOpenInvite(true)}>Invite User</button>

        //             {
        //                 Object.entries(roomInfo.members).map((member, i) => (

        //                     <div className="mc_member_div" >
        //                         <div className="member_img_cont">
        //                             <img src={img} className="m_img" />
        //                         </div>

        //                         <div className="member_name">
        //                             {member[1].name}
        //                         </div>
        //                         <button type="button"  onClick={() => KickUser(member[0])}>Remove User</button>

        //                     </div>
        //                 ))
        //             }
        //         </div>

        //         <div className="gim_footer_cont">
        //             <div className="exit_div" onClick={LeaveRoom} >
        //                 <span className="fa fa-sign-out exit_icon" ></span>

        //                 <span className="text" > Exit group  </span>

        //             </div>

        //               <div className="report_div">
        //                 <span className="fa fa-thumbs-down report_icon" ></span>

        //                 <span className="text" > Report group  </span>

        //             </div>

        //         </div>
        //     </div>

        //     {openInvite && (
        //         <InviteUser
        //             open={openInvite}
        //             setOpen={setOpenInvite}
        //             inviteUserCallBack={inviteUserCallBack}
        //             inviteUserListCallBack={inviteUserListCallBack}
        //             roomInfo={roomInfo}
        //         />
        //     )}
        // </ReusableSwipeDrawer>
    );
};

export default RightChatRoomInfo;
