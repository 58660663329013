import React, { useEffect, useState } from "react";
import { useGlobalContext } from "../../../ContextGlobal/ContectGloval";

function VideoCall({
    createVideoCall,
    createAudioCall,
    stopAudioCall,
    rooms,
    selectedRow,
    stopVideoCall
}) {
    const context = useGlobalContext();
    //const [roomId, setRoomId] = useState();
    var ab = context.roomId;
    console.log("rooomi", context.roomId);
    useEffect(() => {
        if (context.newVideo === true) {
            // setRoomId(context.roomId)
            if (
                context.videoCallStart === true &&
                context.audioOnly === true &&
                context.createAudio === true
            ) {

                createAudioCall(selectedRow);
                //setRoomId(context.roomId)

                context.setCreateAudio(false);
            } else if (
                context.videoCallStart === true &&
                context.createVideo === true
            ) {
                console.log("videoStartedaudioStarted");
                createVideoCall(selectedRow,context.acceptCallerName);
                //  setRoomId(context.roomId)

                context.setCreateVideo(false);
            }
            if (window.JitsiMeetExternalAPI) startConference();
        } else {
            console.log("i am not called");
        }
    }, [context.newVideo]);
    let api;
    const startConference = () => {
        const domain = "tv.vjoinlife.com";
        let meetID = ab?ab.split(":"):localStorage.getItem("roomId").split(":");
        console.log("finalhot", meetID[0]);
        let options;
        {
            context.audioOnly === true
                ? (options = {
                      roomName: meetID[0],
                      width: "100%",
                      height: "100%",
                      parentNode: document.querySelector("#jitsicallmeet"),

                      configOverwrite: {
                          disableSimulcast: false,
                          startWithVideoMuted: true
                      },
                      interfaceConfigOverwrite: {
                          filmStripOnly: false,
                          SHOW_JITSI_WATERMARK: false
                      }
                  })
                : (options = {
                      roomName: meetID[0],
                      width: "100%",
                      height: "100%",
                      parentNode: document.querySelector("#jitsicallmeet"),

                      configOverwrite: {
                          disableSimulcast: false

                          //startWithVideoMuted:true,
                      },
                      interfaceConfigOverwrite: {
                          filmStripOnly: false,
                          SHOW_JITSI_WATERMARK: false
                      }
                  });
        }

        api = new window.JitsiMeetExternalAPI(domain, options);
        api.addEventListener(`videoConferenceJoined`, () => {
            const listener = ({ enabled }) => {
                api.removeEventListener(`tileViewChanged`, listener);
                if (!enabled) {
                    api.executeCommand(`toggleTileView`);
                }
            };
        });
        // api.addListener('participantLeft', ({ id }) => {
        //   let count = api.getNumberOfParticipants();
        //   if (count <= 1) {
        //     console.log("urlvideo", context.roomId)
        //     api.dispose();
        //     context.setNewVideo(false);
        //     context.setAudioOnly(false);
        //     stopVideoCall(context.roomId, id, context.lastVideoCallEventID, count);
        //     stopAudioCall(context.roomId, id, context.lastAudioCallEventID, count);
        //   }
        // });
     api.on("readyToClose", () => {
            let count = api.getNumberOfParticipants();
            // if (count <= 1) {
            console.log("urlvideo", context.roomId);
            api.dispose();
            context.setNewVideo(false);
            context.setAudioOnly(false);
            stopVideoCall(
                context.roomId,
                "abc",
                context.lastVideoCallEventID,
                count
            );
            stopAudioCall(
                context.roomId,
                "abc",
                context.lastAudioCallEventID,
                count
            );
            // }
        });
        api.addEventListener("participantRoleChanged", function (event) {
            if (event.role === "moderator") {
                // api.executeCommand('toggleLobby', true);
                console.log("abc_abc");
            }
        });
    };
    return (
        <div
            id="jitsicallmeet"
            className="call-on-big"
            style={{
                
                display: context.newVideo ? "block" : "none"
            }}
        >
            <div
                className="d-none"
                id="autoHangupCall"
                onClick={() =>{
                   api.dispose()
                }
                }
            ></div>
        </div>
    );
}

export default VideoCall;