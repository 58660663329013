import React, { Component } from "react";
import { connect } from "react-redux";
import { authenticate, verifyToken } from "../../actions/auth";
import { withRouter } from "react-router";
import Logo from "../../assets/images/kuumba_logo.png";
import { AUTHENTICATE } from "../../actions/types";
import { useDispatch } from "react-redux";

 let imgDiv = {
    width: "150px",
    position:"absolute",
    top: "60px",
    left: "90px"
  }

   let imgStyle = {
    width: "100%",
    height: "100%",
    objectFit:"contain",
  }

class Authenticate extends Component {
    constructor(props) {

        super(props);
        this.onChangeUsername = this.onChangeUsername.bind(this);
        this.onChangePassword = this.onChangePassword.bind(this);
        this.login = this.login.bind(this);
        this.state = {
            user_email: "",
            user_password: ""
        };
    }
    
    componentDidMount() {
// console.log("mountPhase",this.props.isLoggedIn)
if(this.props.isLoggedIn)
this.props.history.push("/desktop")
const searchParams = new URLSearchParams(this.props.location.search);
        const token = searchParams.get('token');

        if (token) {
            // this.setState({ token });
            this.props.verifyToken(token)
            .then((data) => {
                // console.log(data);
                this.setState({
                    user: data
                });

                this.props.history.push("/desktop");
            })
            .catch((e) => {
                alert("Wrong username and password, please try agains");
                // alert(e);
            });


            console.log('Token:', token); // Optionally log the token
        }
    }

    // handleSubmit(event) {
    // }
// verifyToken(token) {

//     let payload={token:token}
//         fetch('https://tapi.vjoinlife.com/verify_token', {
//             method: 'POST',
//             headers: {
//                 'Content-Type': 'application/json',
//             },
//             body:JSON.stringify(payload)  // Sending the token in the body
//         })
//         .then(response => response.json())
//         .then(data => {
//             if (data.response) {
//                 // alert(JSON.stringify(data.response.detail))
//                 this.props.dispatch({
//                     type: AUTHENTICATE,
//                     payload: data.response.detail, // Assume this is where your user data comes from
//                     isLoggedIn: true,
//                 });
//                 console.log('Token verification successful:', data);
//                 // Handle success case (e.g., set user data, navigate)
//             } else {
//                 console.error('Token verification failed:', data);
//                 // Handle failure case (e.g., show an error message)
//             }
//         })
//         .catch((error) => {
//             console.error('Error verifying token:', error);
//         });
//     }
    login(event) {
        event.preventDefault();

        let { user_email, user_password } = this.state;
        if (!user_email) return ;
        if (!user_password) return;

        this.props
            .authenticate({ user_email, user_password })
            .then((data) => {
                // console.log(data);
                this.setState({
                    user: data
                });

                this.props.history.push("/desktop");
            })
            .catch((e) => {
                alert("Wrong username and password, please try again");
                // alert(e);
            });
    }

    onChangeUsername(e) {
        const user_email = e.target.value;
        this.setState({
            user_email: user_email
        });
    }
    onChangePassword(e) {
        const user_password = e.target.value;
        this.setState({
            user_password: user_password
        });
    }
    render() {
        return (
            <div className="wrappr ">
                <div className="container-fluid bg-chat-section chat-wrappr">
                    <div className="row">
                        <div className="col-12">
                            <select className="vodiapicker ">
                                <option
                                    value="en"
                                    className="test"
                                    data-thumbnail="images/united-states.png"
                                >
                                    English
                                </option>
                                <option
                                    value="gr"
                                    data-thumbnail="https://upload.wikimedia.org/wikipedia/commons/thumb/5/5e/4H_Emblem.svg/1000px-4H_Emblem.svg.png"
                                >
                                    Italian
                                </option>
                            </select>
                            <div className="lang-select float-end">
                                <button
                                    className="btn-select"
                                    value=""
                                ></button>
                                <div className="b">
                                    <ul id="a"></ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div style={{display:"flex" , justifyContent:"center", alignItems:"center"}} className="row">
                        {/* <div className="col-md-6 d-flex align-items-center justify-content-center mt-5">
                            <img
                                src={Logo}
                                alt="kuumba_logo"
                                className="regFormImg"
                            />
                        </div> */}
                        <div style={imgDiv} >
                              <img
                                src={Logo}
                                alt="kuumba_logo"
                                style={imgStyle}
                            />
                        </div>
                        <div className="col-md-5">
                            <div style={{background:"white",borderRadius:"12px",marginRight:"30px"}} className="form-body">
                                <div style={{marginTop:"50px"}} className="row">
                                    <div className="form-holder">
                                        <div className="form-content">
                                            <div className="form-items">
                                                <h3>Account Login</h3>
                                                <div className="pt-2">
                                                    <p>
                                                        Welcome back!
                                                    </p>
                                                </div>
                                                <form
                                                    className="customSignIn"
                                                    onSubmit={this.login}
                                                    // noValidate
                                                >
                                                    <div className="row">
                                                        <div className="col-md-12 mt-2">
                                                            <input
                                                                className="form-control"
                                                                type="texts"
                                                                name="email"
                                                                required
                                                                placeholder=" Your Email Address*"
                                                                onChange={
                                                                    this
                                                                        .onChangeUsername
                                                                }

                                                            />
                                                        </div>
                                                        <div className="col-md-12 mt-2">
                                                            <input
                                                                className="form-control"
                                                                type="password"
                                                                required
                                                                name="floatingPassword"
                                                                placeholder="Password*"
                                                                onChange={
                                                                    this
                                                                        .onChangePassword
                                                                }
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="d-flex justify-content-between">
                                                        <div className="form-check mt-3">
                                                            <input
                                                                className="form-check-input"
                                                                type="checkbox"
                                                                value=""
                                                                id="invalidCheck"
                                                            />
                                                            <span style={{fontSize:"12px"}} className="text_login">
                                                                Remember me
                                                            </span>
                                                        </div>
                                                        <div className="form-check mt-3 text_login ">
                                                            <span style={{fontSize:"12px"}}>
                                                                Forgot password?
                                                            </span>{" "}
                                                            {/* <a
                                                                href="#"
                                                                className="text_login  text-underline"
                                                            >
                                                                Click here
                                                            </a> */}
                                                        </div>
                                                    </div>
                                                    <div  className="form-button mt-3">
                                                        <button  style={{width:"100%"}}
                                                            id="submit"
                                                            type="submit"
                                                            className="btn btn-primary py-3 "
                                                            // onClick={this.login}
                                                        >
                                                            LOGIN
                                                        </button>
                                                    </div>
                                                    <p onClick={()=>this.props.history.push('/register')} className="mt-3  position-relative">
                                                        <span className="text_login">
                                                            Dont have an
                                                            account?{" "}
                                                        </span>
                                                        <a
                                                            // href="/register"
                                                            className="text-underline text_login"
                                                        >
                                                            <strong>
                                                                Click here to
                                                                create one
                                                            </strong>
                                                        </a>

                                                    </p>
                                                    <ul className="list-unstyled text_login policy-nav-login d-flex">
                                <li className="me-3">&copy; 2023 - *</li>
                                {/* <li className="me-3">
                                    <a
                                        className="text_login text-decoration-none"
                                        href=""
                                    >
                                        Privacy Policy
                                    </a>
                                </li>
                                <li className="me-3">
                                    <a
                                        className="text_login text-decoration-none"
                                        href=""
                                    >
                                        Terms of Use
                                    </a>
                                </li> */}
                            </ul>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
const mapStateToProps = (state) => {
    return {
        loggedInUser: state.auth?.user,
        isLoggedIn: state.auth.loggedIn,

    };
};
export default connect(mapStateToProps, { authenticate,verifyToken })(
    withRouter(Authenticate)
);
