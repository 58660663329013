import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { Provider } from 'react-redux';
import { persistor, store } from './store';
import { ThemeProvider } from '@material-ui/core';
import { theme } from './Theme';
import { PersistGate } from 'redux-persist/integration/react'

if (process.env.NODE_ENV === 'development'||'production') {
   console.log = () => {}
  console.error = () => {}
  console.debug = () => {}
  // console.log = () => {}
  // console.error = () => {}
  // console.debug = () => {}

}
ReactDOM.render(
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>

        <ThemeProvider theme={theme}>
          <App />
        </ThemeProvider>
      </PersistGate>

    </Provider>,
  document.getElementById('root')
);