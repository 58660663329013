import http from "../http-common";
class DesktopDataService {
  getAllByEmployeeId(data) {
    return http.post(`/get_tickets_by_employee`, data);
  }

  getSearchUsers(data) {
    console.log("dataaaa",data)
    return http.post(`/api/adm/searchusers.jsp?q=${data.search_name}`);
  }
}
export default new DesktopDataService();