import React, { useState, useEffect, useRef } from "react";
import Picker from "emoji-picker-react";
import ActionWrapper from "./ActionWrapper";
import Emoji from "../../../assets/svg/Emoji.svg";
import $ from "jquery";

import { useGlobalContext } from "../../ContextGlobal/ContectGloval";
import "react-h5-audio-player/lib/styles.css";
import AudioRecord from "./AudioRecord";
import textMessage from "../../../assets/ringtones/one-drop-shortmp3-com-2270.mp3";
import PopoverReuse from "./ReusableComponnet/Popover";
import { emit } from "process";
const RightChatWindowTypingPane = (props) => {
    const [audioui, setAudioUI] = useState(false);
    const [messageText, setMessageText] = useState(``);
    const [hide, setHide] = useState(false);
    const [menuOpenAudio, setMenuOpenAudio] = useState(false);
    const [forStart, setForStart] = useState(false);
    const [openPopup, setOpenPopup] = useState(false);

    const context = useGlobalContext();
    const {
        sendMessageCallBack,
        sendTypingCallBack,
        room,
        currentUser,
        sendLocationCallBack,
        sendImageCallBack,
        sendVideoCallBack,
        sendAudioCallBack,
        replyMessageCallback,
        replySendLocationCallback,
        replyUploadFileCallback
    } = props;

    const [showPickerTitle, setShowPickerTitle] = useState(null);
    const [anchorEl, setAnchorEl] = useState(null);

    const onEmojiClickTitle = (event, emojiObject) => {
        // console.log("calledemoji",emojiObject.emoji)
        setMessageText((prevInput) => prevInput + emojiObject.emoji);
        setShowPickerTitle(false);
    };
    // console.log("roomName", room);
    // let a = Object.values(props?.room?.members).map((val) => {
    //     console.log("arrayVal", val.name);
    // });
    // if (isTyping) {
    //     console.log("USER IS TYPING....")
    //     console.log("CHECKING VALUES:", { room: room.roomId, currentUser: currentUser.user_id });
    //     // sendTypingCallBack(room.roomId, currentUser.user_id);
    // }
    // document.querySelectorAll("textarea").forEach((element) => {
    //     element.style.height = `${element.scrollHeight}px`;
    //     element.addEventListener("input", (event) => {
    //         event.target.style.height = "auto";
    //         event.target.style.height = `${event.target.scrollHeight}px`;
    //     });
    // });
    // $("textarea").keydown(function(e){
    //     // Enter was pressed without shift key
    //     if (e.key == 'Enter' && !e.shiftKey)
    //     {
    //         // prevent default behavior
    //         e.preventDefault();
    //     }

    // })

    // $("#streamWriter").keypress(function (e) {
    //     try {
    //         if (e.which === 13 && !e.shiftKey) {
    //             e.preventDefault();
    //             document.getElementById("button").click();
    //             document.querySelectorAll("textarea").forEach((element) => {
    //                 element.style.height = "35px";
    //                 element.addEventListener("input", (event) => {
    //                     event.target.style.height = "auto";
    //                     event.target.style.height = `${event.target.scrollHeight}px`;
    //                 });
    //             });

    //             // $(this).closest("form").submit();
    //         }
    //     } catch (e) {}
    // });

    const handleClickEmoji = (event) => {
        setShowPickerTitle(event.currentTarget);
    };
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleCloseEmoji = () => {
        setShowPickerTitle(null);
    };

    const open = Boolean(anchorEl);

    const openEmoji = Boolean(showPickerTitle);
    const idEmoji = openEmoji ? "simple-popover" : undefined;
    const id = open ? "simple-popover" : undefined;
    const handleKeyDown = (e) => {
        if (e.key === 'Enter' && !e.shiftKey) {
            e.preventDefault(); // Prevents new line in textarea on Enter press
            if (messageText.length < 1) {
                // console.log("length");
                return;
            }
    
            const local_path = {
                body: messageText,
                msgtype: "m.text"
            };
            context?.setGoBottom(true);
    
            setMessageText("");
            context.setReplyMessage("");
            sendMessageCallBack(local_path, room, currentUser);        }

    };
    const handleInputChange = (e) => {
        let a = e.target.value;

        if (a[a.length - 1]?.includes("@")) {
            // console.log("ahmadwardah1");
            setOpenPopup(true);
        } else {
            // console.log("ahmadwardah2");

            setOpenPopup(false);
        }
        // if (e.target.value !== "" || messageText.length<=5) {

        if (messageText.length <= 5) {
            // console.log("INPUT HAS A VALUE!!!!!!!");
            sendTypingCallBack(room.roomId, currentUser.user_id);
        }
    };
    const textareaRef = useRef(null);
    const maxHeight = 150; // Maximum height for the textarea

//     useEffect(() => {
//    if (textareaRef.current) {
//       // Reset the height to allow shrinking
//       textareaRef.current.style.height = 'auto';

//       // Dynamically set the height of the textarea based on the scrollHeight, but max out at `maxHeight`
//       const newHeight = Math.min(textareaRef.current.scrollHeight, maxHeight);
//       textareaRef.current.style.height = `${newHeight}px`;

//       // Enable scrolling if content exceeds the max height
//       textareaRef.current.style.overflowY = textareaRef.current.scrollHeight > maxHeight ? 'auto' : 'hidden';
//     }
//     }, [messageText]);

    useEffect(() => {
        if (messageText.length >= 1) return setHide(true);
        else return setHide(false);
    }, [messageText]);

    async function play() {
        var sound = document.getElementById("audio");
        sound.currentTime = 0;
        await sound.play();
    }
    const handleSubmit = (evt) => {
        evt.preventDefault();
        // console.log("textMessages",messageText===" ")
        // console.log("local path");
 // Reset the height to the default minHeight (e.g., 40px)
//  if (textareaRef.current) {
//     textareaRef.current.style.height = `${40}px`;
//     textareaRef.current.style.overflowY = 'hidden'; // Hide the scrollbar when cleared
//   }
        if (messageText.length < 1) {
            // console.log("length");
            return;
        }

        const local_path = {
            body: messageText,
            msgtype: "m.text"
        };
        context?.setGoBottom(true);

        setMessageText("");
        context.setReplyMessage("");
        sendMessageCallBack(local_path, room, currentUser);

        //    alert( window.navigator.onLine ? 'on' : 'off')
        play();
        //    alert( window.navigator.onLine ? 'on' : 'off')
        // setTypingIndicatorForRoom(roomId, userId)
    };
    const handleAudio = (e) => {
        navigator.mediaDevices
            .getUserMedia({ audio: true })
            .then((stream) => {
                setMenuOpenAudio(true);
                setAudioUI(true);
                setForStart(true);
                console.log(
                    "MediaStreamTrack muted:",
                    stream.getAudioTracks()[0].muted
                );
            })
            .catch((err) => {
                console.log(err);
                alert("Please Allow the microphone...");
            });
        // navigator.webkitGetUserMedia(
        //     { audio: "active" },
        //     function () {
        //         setMenuOpenAudio(true);
        //         setAudioUI(true);
        //         setForStart(true);
        //     },
        //     function () {
        //         alert("Please Allow the microphone...");
        //     }
        // );
    };

    const handleReply = (evt) => {
        evt.preventDefault();
        if (messageText.length < 1) {
            return;
        }
        // console.log("room_room_room_messageText", context.replyMessage);

        // console.log("room_room_room" + room);
        replyMessageCallback(room.roomId, context.replyMessage, messageText);

        setMessageText("");
        context.setReplyMessage("");
    };

    const menuItems = [
        {
            icon: "fa fa-camera",
            text: "Camera",
            type: "camera"
        },
        {
            icon: "fa fa-file-image-o",
            text: "Gallery",
            type: "gallery"
        },
        {
            icon: "fa fa-microphone",
            text: "Audio",
            type: "audio"
        },
        {
            icon: "fa fa-file",
            text: "Document",
            type: "document"
        },
        {
            icon: "fa fa-map-marker",
            text: "Location",
            type: "location"
        }
     
    ];
    return (
        <form
            id="form"
            autoComplete="off"
            style={{ display: "flex" }}
            className="input-container"
            onSubmit={context.replyMessage ? handleReply : handleSubmit}
        >
            {audioui ? (
                <div style={{ width:"100%" }}>
                    <AudioRecord
                        room={room}
                        sendAudioCallBack={sendAudioCallBack}
                        menuOpenAudio={menuOpenAudio}
                        setAudioUI={setAudioUI}
                        forStart={forStart}
                    />
                </div>
            ) : (
                <>
                    <div className="divider"></div>
                    <div
                        style={{
                         
                            width: "100%",
                            paddingLeft: "10px"
                        }}
                        className="  align-items-center "
                    >
                        <div style={{ display: "flex", alignItems: "center" }}>
                            <img
                                style={{ marginRight: "0px" }}
                                className="emoji"
                                // src="https://icons.getbootstrap.com/assets/icons/emoji-smile.svg"
                                src={Emoji}
                                width="20px"
                                height="20px"
                                onClick={handleClickEmoji}
                                alt="imoji"
                            />
                            <PopoverReuse
                                id={idEmoji}
                                open={openEmoji}
                               

                                anchorEl={showPickerTitle}
                                onClose={handleCloseEmoji}
                                anchorOrigin={{
                                    vertical: "top",
                                    horizontal: "left"
                                }}
                                transformOrigin={{
                                    vertical: "bottom",
                                    horizontal: "left"
                                }}
                                cssClass={"MuiPopovers-paper-emoji"}
                            >
                                <Picker
                                    pickerStyle={{
                                        width: "100%",
                                       
                                        width: "350px"
                                    }}
                                    onEmojiClick={onEmojiClickTitle}
                                />
                            </PopoverReuse>
                            <i
                                className="fa fa-paperclip me-2 paper_clip"
                                style={{ color: "#3a5564" }}
                                onClick={handleClick}
                            ></i>

                            <PopoverReuse
                                id={id}
                                open={open}
                           

                                anchorEl={anchorEl}
                                onClose={handleClose}
                                anchorOrigin={{
                                    vertical: "top",
                                    horizontal: "right"
                                }}
                                transformOrigin={{
                                    vertical: "bottom",
                                    horizontal: "right"
                                }}
                                cssClass={"MuiPopovers-paper-menu"}
                            >
                                <ul className="dropUpCustom">
                                    {menuItems.map((item, i) => (
                                        <a
                                            key={i}
                                            onClick={() => {
                                                context.setOpenInputAuto(
                                                    item.type
                                                );
                                                context.setOpenWrapper(
                                                    item.type
                                                );
                                                context.setUploadButtonShow(
                                                    false
                                                );
                                                context.setDropImage();
                                                handleClose();
                                            }}
                                        >
                                            <i
                                                style={{
                                                    fontSize: "20px",
                                                    borderRadius: "50%",
                                                    width: "35px",
                                                    height: "35px",
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    color: "white",
                                                    backgroundColor: "#19C39F "
                                                }}
                                                className={item.icon}
                                            ></i>
                                        </a>
                                    ))}
                                </ul>
                            </PopoverReuse>
                            <textarea
                                onKeyDown={handleKeyDown}
                                onChange={(e) => (
                                    setMessageText(e.target.value),
                                    setOpenPopup(false),
                                    handleInputChange(e)
                                )}
                                placeholder="Type your message..."

                                // style={{ height: inputHeight }}
                                className="chat-textarea"
                                value={messageText}
                            />

                            {/* y */}

                            {hide === false ? (
                                <i
                                    style={{
                                        marginRight: "10px !important",
                                        color: "#3a5564"
                                    }}
                                    className="fa fa-microphone me-2 paper_clip"
                                    onClick={handleAudio}
                                ></i>
                            ) : (
                                // <Button
                                //     style={{
                                //         border: "1px solid white",
                                //         marginRight: "5px",
                                //         marginLeft: "auto",
                                //     }}
                                //     onClick={handleAudio}
                                //     shape="circle"
                                //     icon={

                                //         <AudioTwoTone
                                //             style={{
                                //                 display: "flex",
                                //                 justifyContent: "center"
                                //             }}
                                //             twoToneColor="white"
                                //         />
                                //     }
                                //     size={"medium"}
                                // />
                                <div>
                                    <button
                                        id="button"
                                        type="submit"
                                        style={{ display: "contents" }}
                                    >
                                        {/* <img
        style={{
            marginRight: "15px",
            marginLeft: "auto"
        }}
        src={Send}
        width="21px"
        height="21px"
        alt="send"
    /> */}
                                        <i
                                            style={{
                                                marginRight: "15px !important",

                                                color: "#3a5564"
                                            }}
                                            className="fa fa-paper-plane me-2 paper_clip"
                                        ></i>
                                        <audio
                                            src={textMessage}
                                            autostart="false"
                                            width="0"
                                            height="0"
                                            id="audio"
                                        />
                                    </button>
                                </div>
                            )}
                        </div>
                        {context.openWrapper && (
                            <ActionWrapper
                                // setOpenWrapper={setOpenWrapper}
                                // openWrapper={openWrapper}
                                sendLocationCallBack={sendLocationCallBack}
                                replySendLocationCallback={
                                    replySendLocationCallback
                                }
                                room={room}
                                sendImageCallBack={sendImageCallBack}
                                replyUploadFileCallback={
                                    replyUploadFileCallback
                                }
                                sendVideoCallBack={sendVideoCallBack}
                                sendAudioCallBack={sendAudioCallBack}
                            />
                        )}

                        {openPopup && (
                            <ul className="dropUpCustom">
                                {/* Object.values(props?.room?.members).map((val) => {
        console.log("arrayVal", val.name);
    }); */}
                                {Object?.values(props?.room?.members).map(
                                    (val, i) => (
                                        <a
                                            key={i}
                                            onClick={() => {
                                                setMessageText(
                                                    (prevInput) =>
                                                        prevInput +
                                                        `${val.name} `
                                                );
                                                setOpenPopup(false);
                                            }}
                                        >
                                            {/* <i className={item.icon}></i> */}
                                            <i className="img-sec me-3">
                                                <img
                                                    style={{
                                                        width: "49px",
                                                        height: "49px"
                                                    }}
                                                    src="https://mdbcdn.b-cdn.net/img/new/avatars/2.webp"
                                                    className="rounded-circle"
                                                    width="150px"
                                                    alt="Avatar"
                                                />
                                            </i>
                                            {val.name}
                                        </a>
                                    )
                                )}
                            </ul>
                        )}
                        <span style={{ display: "none" }}>
                            {/* <textarea
                                rows="1"
                                style={{ paddingTop: "8px", bottom: "19%" }}
                                spellCheck="false"
                                onChange={(e) => (
                                    setMessageText(e.target.value),
                                    setOpenPopup(false),
                                    handleInputChange(e)
                                )}
                                value={messageText}
                                //   ref={firstRef}
                                autoFocus
                                type="text"
                                id="streamWriter"
                                placeholder="Write Message..."
                                className="form-control   border-0  msg_write"
                            ></textarea> */}
                            <input type="text" />

                            {/* y */}

                            {hide === false ? (
                                <i
                                    style={{
                                        marginRight: "10px !important",
                                        marginLeft: "auto",
                                        color: "#3a5564"
                                    }}
                                    className="fa fa-microphone me-2 paper_clip"
                                    onClick={handleAudio}
                                ></i>
                            ) : (
                                // <Button
                                //     style={{
                                //         border: "1px solid white",
                                //         marginRight: "5px",
                                //         marginLeft: "auto",
                                //     }}
                                //     onClick={handleAudio}
                                //     shape="circle"
                                //     icon={

                                //         <AudioTwoTone
                                //             style={{
                                //                 display: "flex",
                                //                 justifyContent: "center"
                                //             }}
                                //             twoToneColor="white"
                                //         />
                                //     }
                                //     size={"medium"}
                                // />
                                <div>
                                    <button
                                        id="button"
                                        type="submit"
                                        style={{ display: "contents" }}
                                    >
                                        {/* <img
                                    style={{
                                        marginRight: "15px",
                                        marginLeft: "auto"
                                    }}
                                    src={Send}
                                    width="21px"
                                    height="21px"
                                    alt="send"
                                /> */}
                                        <i
                                            style={{
                                                marginRight: "15px !important",

                                                color: "#3a5564"
                                            }}
                                            className="fa fa-paper-plane me-2 paper_clip"
                                        ></i>
                                        <audio
                                            src={textMessage}
                                            autostart="false"
                                            width="0"
                                            height="0"
                                            id="audio"
                                        />
                                    </button>
                                </div>
                            )}
                        </span>
                    </div>
                </>
            )}
        </form>
    );
};

export default RightChatWindowTypingPane;
