import {
  FETCH_DESKTOP, FETCH_DESKTOP_ERROR,TESTING_CASE, SELECT_ROOM, SEARCH_USER_CREATE_ROOM, SEARCH_USER_CREATE_ROOM_ERROR


} from "./types";

import DesktopDataService from "../services/desktop";
export const findTicketsByEmployeeId = (employeeId) => async (dispatch) => {


  try {
    const data = {
      "employee_id": employeeId
    };
    const res = await DesktopDataService.getAllByEmployeeId(data);
    
    dispatch({
      type: FETCH_DESKTOP,
      payload: res.data.response.result,
    });
  }
  catch (e) {
    dispatch({
      type: FETCH_DESKTOP_ERROR,
      payload: console.log(e),
    })
  }


};
      //for testing case only ahmad

export const userSearch=(name)=>async (dispatch)=>{
dispatch({
  type: TESTING_CASE,
  payload: name,
});
}

const convertNameIntoFormat=(user)=>{
  console.log("nammemem",user)

  const newArr=[...user]
  newArr.forEach(element => {
    // console.log("nammemem",element.name)

    return element.name=`@${element.name}:tm.vjoinlife.com`
  });
}
export const searchUserCreateRoom = (payload) => async (dispatch) => {
  try {
    const data = {
      "search_name": payload
    };
    const res = await DesktopDataService.getSearchUsers(data);
     console.log("payload data", res.data.response.detail)
     convertNameIntoFormat(res.data.response.detail)
    dispatch({
      type: SEARCH_USER_CREATE_ROOM,
      payload: res.data.response.detail,
    });
  }
  catch (e) {
    dispatch({
      type: SEARCH_USER_CREATE_ROOM_ERROR,
      payload: console.log(e),
    })
  }
};

export const selectRoom = (room) => async (dispatch) => {
  // alert("action seleect room = "+JSON.stringify(room))
  let messages = room?.messages;
  // for (var i = 0; i < messages.length; i++) {
  //   let message = messages[i];
  //   message.isRead = true;
  // }
  dispatch({
    type: SELECT_ROOM,
    payload: room,
  });

};



