import React, { useState } from "react";
import "./styles.css";
import Logo from "../../assets/images/kuumba_logo.png";
import Placeholder from "../../assets/svg/placeholder.svg";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import placeholder2 from "../../assets/images/2.png";
import PopoverReuse from "../desktop/DesktopComponents/ReusableComponnet/Popover";
import { MenuItem } from "@material-ui/core";
import { useDispatch } from "react-redux";
import { logout } from "../../actions/auth";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import ProfileEdit from "./profileEdit";
import ReusableSwipeDrawer from "../desktop/DesktopComponents/ReusableSwipeDrawer";
import { FormLabel } from "@mui/material";
import { useGlobalContext } from "../ContextGlobal/ContectGloval";
function TopBar(props) {
    const { image, expandDropdown, valueDark, leftPanelHide, setAvatarUrl,getAvatarUrl } =
        props;
    const [opens, setOpen] = useState(false);
    const { settingTune, setSettingTune } = useGlobalContext();
    const [openSetting, setOpenSetting] = useState(false);
    const toggleDrawer = (open) => (event) => {
        if (
            event &&
            event.type === "keydown" &&
            (event.key === "Tab" || event.key === "Shift")
        ) {
            return;
        }
        setOpenSetting(open);
    };
    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleSetting = () => {};
    const history = useHistory();
    const dispatch = useDispatch();
    const LogOut = () => {
        dispatch(logout());
        history.push("/");
        localStorage.clear();
        var req = indexedDB.deleteDatabase("matrix-js-sdk:default");
        req.onsuccess = function () {
            console.log("Deleted database successfully");
        };
        req.onerror = function () {
            console.log("Couldn't delete database");
        };
        req.onblocked = function () {
            console.log(
                "Couldn't delete database due to the operation being blocked"
            );
        };
    };
    const open = Boolean(anchorEl);
    const id = open ? "simple-popover" : undefined;

    const userName = (val) => {
        if (val?.includes("_")) {
            return val?.split(":")[0].split("@")[1].split("_")[0];
        }

        return val?.split(":")[0].split("@")[1];
    };
    const handleChangeTune = (e) => {
        const { name, checked } = e.target;
        console.log(`Handling change for ${name}, checked: ${checked}`);
      
        setSettingTune((prevSettingTune) => ({
          ...prevSettingTune,
          [name]: checked,
        }));
      };
    return (
        <>
            <div className="topbar">
                <div className="topbar_left">
                    <img
                        src={image ? Placeholder : Logo}
                        alt="logo"
                        className="topbar_logo_"
                    />
                    <div className="buttonShow">
                        <button onClick={() => leftPanelHide()}>ahmad</button>
                    </div>
                    {/* <BsList
                        className={`topbar_line_ ${
                            expand
                                ? "line_toggle_toword"
                                : "line_toggle_forword"
                        }`}
                        onClick={toglleHandler}
                    /> */}
                </div>
                <div className="topbar_Middle">
                    <div className="topbar_search_box">{/* <Search /> */}</div>
                </div>
                <div className="topbar_right">
                    {/* <div onClick={() => darkOn()} className="dark_light_toggle">
                        <BsMoonFill />
                    </div> */}
                    {/* <div className="topbar_title ">{helloText}</div> */}
                    <div onClick={handleClick} className="img-sec me-2">
                        <img
                            style={{ width: "30px", height: "30px" }}
                            src={placeholder2}
                            className="rounded-circle"
                            width="150px"
                            alt="Avatar"
                        />

                        {/* style={{width: 40, height: 40, borderRadius: 40/ 2}}  */}
                        {/* <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTGLAUGhvGs1mg90ntWQRy0zWejCoWb7fw3ZlZvUCEj3OAtoAWfnlVtiHo7wLptXg451-E&usqp=CAU" /> */}
                    </div>
                    <PopoverReuse
                        id={id}
                        open={open}
                        // anchorPosition={{ top: 228, left: 518 }}
                        // anchorReference="anchorPosition"

                        anchorEl={anchorEl}
                        onClose={handleClose}
                        anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "left"
                        }}
                        cssClass={"topbar-logout"}
                    >
                        <div>
                            <MenuItem>
                                <p
                                    onClick={() => {
                                        setOpen(true);
                                        handleClose();
                                    }}
                                    className="fa fa-user-plus"
                                >
                                    {" "}
                                    Profile
                                </p>
                            </MenuItem>
                            <MenuItem>
                                <p
                                    onClick={() => {
                                        setOpenSetting(true);
                                        handleClose();
                                    }}
                                    className="fa fa-cog"
                                >
                                    {" "}
                                    Setting{" "}
                                </p>
                            </MenuItem>
                            <MenuItem>
                                <p onClick={LogOut} className="fa fa-sign-out">
                                    {" "}
                                    Log Out
                                </p>
                            </MenuItem>
                        </div>
                    </PopoverReuse>

                    <div className="topbar_title ">
                        {userName(localStorage.getItem("mxvr_user_id"))}
                    </div>
                    {/* <div
                        className={`topbar_arrow ${
                            dropDown
                                ? "line_toggle_toword"
                                : "line_toggle_forword"
                        }`}
                        onClick={dropDownHandler}
                    >
                        <img src={Arrow} alt="logo" className="arrow_" />
                    </div> */}
                </div>
                {/* {dropDown ? (
                    <div className="logout_box">
                        <LogOutDropDown />
                    </div>
                ) : (
                    ""
                )} */}
                {opens&&
                <ProfileEdit
                    opens={opens}
                    setOpen={setOpen}
                    header={"Profile Info"}

                    setAvatarUrl={setAvatarUrl}
                    getAvatarUrl={getAvatarUrl}
                    userName={userName(localStorage.getItem("mxvr_user_id"))}
                />
                }
                <ReusableSwipeDrawer
                    open={openSetting}
                    width={300}
                    toggleDrawer={(val) => toggleDrawer(val)}
                >
                    <div className="groupInfo_main">
                        <div className="gim_header_top">
                            <span
                                className="fa fa-close close_icon"
                                onClick={() => setOpenSetting(false)}
                            />
                            <span className="ht_title"> Setting </span>
                        </div>
                        <div style={{ marginTop: "75px", paddingLeft: "25px" }}>
                            <div>
                                <FormGroup>
                                    <FormLabel component="legend">
                                        Assign responsibility
                                    </FormLabel>

                                    <FormControlLabel
                                        label="Message Tune"
                                        control={
                                            <Switch
                                                name="messageTune"
                                                checked={settingTune?.messageTune}
                                                onChange={handleChangeTune}
                                            />
                                        }
                                    />
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                name="callTune"
                                                checked={settingTune?.callTune}
                                                onChange={handleChangeTune}

                                            />
                                        }
                                        label="Call Tune"
                                    />
                                    <FormControlLabel
                                        disabled
                                        control={<Switch />}
                                        label="Disabled"
                                    />
                                </FormGroup>
                            </div>
                        </div>
                    </div>
                </ReusableSwipeDrawer>
            </div>
        </>
    );
}

export default TopBar;
