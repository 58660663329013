import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import "font-awesome/css/font-awesome.min.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { syncRooms } from "../../actions/messagingSDK";
import { setClient } from "../../actions/messagingSDK";
import Client from "../../sdk/Client";
import "../desktop/desktop.css";
import LeftPane from "./DesktopComponents/LeftPane";
import RightChatWindow from "./DesktopComponents/RightChatWindow";
import { ContextGlobal } from "../ContextGlobal/ContectGloval";
import VideoCall from "./DesktopComponents/VideoCall/VideoCall";
import TopBar from "../topBar";
import SideBar from "../sideBar";
import whatsapp from "../../assets/svg/whatsapp.svg";
import { LinearProgress } from "@mui/material";

import { serverUrl } from "../../url";

class Desktop extends Component {
    
    constructor(props) {
        super(props);
        this.sdkClient = null;
        this.state = {
            // selectedRow: null,
            showModal: false,
            sync:true,
            expandDropdown: true,
            leftPanelHide:true,
            darkOn: false
            // messages: messages,
        };
        // setTimeout(() => {
        // }, 10000);
        this.darkOn = this.darkOn.bind(this);
        this.fowardMatrixUploadFile = this.fowardMatrixUploadFile.bind(this);

        this.expandDropdown = this.expandDropdown.bind(this);
        this.showModal = this.showModal.bind(this);
        this.hideModal = this.hideModal.bind(this);
        this.syncComplete = this.syncComplete.bind(this);
        this.message = this.message.bind(this);
        this.sendTextMessage = this.sendTextMessage.bind(this);
        this.setTypingIndicatorForRoom =
            this.setTypingIndicatorForRoom.bind(this);
        this.sendLocation = this.sendLocation.bind(this);
        this.setAvatarUrl=this.setAvatarUrl.bind(this)
        this.getAvatarUrl=this.getAvatarUrl.bind(this)
        this.setRoomAvatarUrl=this.setRoomAvatarUrl.bind(this)
        this.getRoomAvatarUrl=this.getRoomAvatarUrl.bind(this)
        this.typing = this.typing.bind(this);
        this.matrixUploadFile = this.matrixUploadFile.bind(this);
        this.createVideoCall = this.createVideoCall.bind(this);
        this.createAudioCall = this.createAudioCall.bind(this);
        this.getNextPage = this.getNextPage.bind(this);
        this.setGroupName = this.setGroupName.bind(this);
        this.setTopic = this.setTopic.bind(this);
        this.createGroup = this.createGroup.bind(this);
        this.stopAudioCall = this.stopAudioCall.bind(this);
        this.stopVideoCall = this.stopVideoCall.bind(this);
        this.isInitialSyncComplete = this.isInitialSyncComplete.bind(this);
        this.stopVideoCallEventHandler =
            this.stopVideoCallEventHandler.bind(this);
        this.leaveRoomCall = this.leaveRoomCall.bind(this);
        this.kickUserCall = this.kickUserCall.bind(this);
        this.inviteUserCall = this.inviteUserCall.bind(this);
        this.inviteUserListCall = this.inviteUserListCall.bind(this);
        this.cancelAudioCall = this.cancelAudioCall.bind(this);
        this.cancelVideoCall = this.cancelVideoCall.bind(this);
        this.timeoutAudioCall = this.timeoutAudioCall.bind(this);
        this.timeoutVideoCall = this.timeoutVideoCall.bind(this);
        this.replyMessage = this.replyMessage.bind(this);
        this.replyUploadFile = this.replyUploadFile.bind(this);
        this.replySendLocation = this.replySendLocation.bind(this);
        this.sendReaction = this.sendReaction.bind(this);
        this.search = this.search.bind(this);
        this.getPreviousPage = this.getPreviousPage.bind(this);
        this.setReadMarker = this.setReadMarker.bind(this);
        this.receipt = this.receipt.bind(this);
        this.createRoom = this.createRoom.bind(this);
        this.joinedRoom = this.joinedRoom.bind(this);
                

        
    }
    showModal = () => {
        this.setState({ showModal: true });
    };
    darkOn = () => {
        this.setState({ darkOn: !this.state.darkOn });
    };

    expandDropdown = () => {
        this.setState({ expandDropdown: !this.state.expandDropdown });
    };
    leftPanelHide=()=>{
        this.setState({ leftPanelHide: !this.state.leftPanelHide });

    }
    hideModal = () => {
        this.setState({ showModal: false });
    };
    receipt(allRooms) {
        // alert("126====="+JSON.stringify(allRooms.allRooms))
        //// console.log("receipt OverAllMessages", allRooms);
        this.props
            .syncRooms(allRooms.allRooms)
            .then((data) => {
                //// console.log("helloooo====", data);
                // alert("helloooo====", JSON.stringify(data));
            })
            .catch((e) => {
                alert(e);
            });
    }
    
    joinedRoom(allRooms) {
        // alert("126====="+JSON.stringify(allRooms.allRooms));
     //   console.log("OverAllMessages joinedRoom", allRooms);
        this.props
            .syncRooms(allRooms.allRooms)
            .then((data) => {
             //   console.log("helloooo====", data);
                // alert("helloooo====", JSON.stringify(data));
            })
            .catch((e) => {
                alert(e);
            });
    }    

    createRoom(allRooms) {
        // alert("126====="+JSON.stringify(allRooms.allRooms));
     //   console.log("OverAllMessages createRoom", allRooms);
        this.props
            .syncRooms(allRooms.allRooms)
            .then((data) => {
             //   console.log("helloooo====", data);
                // alert("helloooo====", JSON.stringify(data));
            })
            .catch((e) => {
                alert(e);
            });
    }    
    message(allRooms) {
        // alert("126====="+JSON.stringify(allRooms.allRooms))
     //   console.log("OverAllMessages", allRooms);
        this.props
            .syncRooms(allRooms.allRooms)
            .then((data) => {
             //   console.log("helloooo====", data);
                // alert("helloooo====", JSON.stringify(data));
            })
            .catch((e) => {
                alert(e);
            });
    }
    typing(allRooms) {
        // alert("126====="+JSON.stringify(allRooms.allRooms))
     //   console.log("DESKTOP TYPING ALL ROOMS :", allRooms);
        this.props
            .syncRooms(allRooms.allRooms)
            .then((data) => {
             //   console.log("58 helloooo====", data);
                // alert("helloooo====", JSON.stringify(data));
            })
            .catch((e) => {
                alert(e);
            });
    }
    videoCallStopped(allRooms) {
        // alert("126====="+JSON.stringify(allRooms.allRooms))
        this.props
            .syncRooms(allRooms.allRooms)
            .then((data) => {
             //   console.log("58 helloooo====", data);
                // alert("helloooo====", JSON.stringify(data));
            })
            .catch((e) => {
                alert(e);
            });
    }
    audioCallStopped(allRooms) {
        // alert("126====="+JSON.stringify(allRooms.allRooms))
        this.props
            .syncRooms(allRooms.allRooms)
            .then((data) => {
             //   console.log("58 helloooo====", data);
                // alert("helloooo====", JSON.stringify(data));
            })
            .catch((e) => {
                alert(e);
            });
    }
    fowardMatrixUploadFile(room, fileUrl, thumbnailFileUrl, msgtype) {
        this.sdkClient.fowardMatrixUploadFile(
            room,
            fileUrl,
            thumbnailFileUrl,
            msgtype
        );
    }
    matrixUploadFile(local_path, room, thumbnail) {
        this.sdkClient.matrixUploadFile(local_path, room, thumbnail);
    }
    setReadMarker(room_id, event_id) {
        this.sdkClient.setReadMarker(room_id, event_id);
    }
    sendTextMessage(content, room, currentUser, isForwarded) {
        this.sdkClient.sendTextMessage(content, room, isForwarded);
    }
    forwardLocation(content, forwardLocation, isForwarded) {
        this.sdkClient.forwardLocation(content, forwardLocation, isForwarded);
    }
    isInitialSyncComplete() {
        this.sdkClient?.isInitialSyncComplete();
    }
    search(term) {
        this.sdkClient.search(term);
    }
    setTypingIndicatorForRoom(roomId, userId) {
        this.sdkClient.setTypingIndicatorForRoom(roomId, userId);
    }

    sendLocation(room, content, imageUrl, locationUrl) {
        this.sdkClient.sendLocation(room, content, imageUrl, locationUrl);
    }
    sendReaction(userId, chatMessage, key) {
        this.sdkClient.sendReaction(userId, chatMessage, key);
    }

    createGroup(options) {
        this.sdkClient.createRoom(options);
    }
    getNextPage(room_id, event_id) {
        this.sdkClient.getNextPage(room_id, event_id);
    }
    setGroupName(name, id) {
        this.sdkClient.setGroupName(name, id);
    }
    setTopic(name, id) {
        this.sdkClient.setTopic(name, id);
    }
  

    
    setAvatarUrl(file){
      return  this.sdkClient.setAvatarUrl(file)
    }
    getAvatarUrl(roomId){
      return  this.sdkClient.getAvatarUrl(roomId)
    }
    setRoomAvatarUrl(file,roomId){
       return this.sdkClient.setRoomAvatarUrl(file,roomId)
    }
    getRoomAvatarUrl(roomId){
       return this.sdkClient.getRoomAvatarUrl(roomId)
    }
    getPreviousPage(room_id, event_id) {
        this.sdkClient.getPreviousPage(room_id, event_id);
    }
    createVideoCall(room, content) {
        this.sdkClient.createVideoCall(room, content);
    }
    // stopVideoCall(room, content) {
    //   this.sdkClient.stopVideoCall(room, content);
    // }
    // stopAudioCall(room, content) {
    //   this.sdkClient.stopAudioCall(room, content);
    // }

    createAudioCall(room, content) {
        this.sdkClient.createAudioCall(room, content);
    }

    stopVideoCall(room, participant, eventId, numberOfPArticipants) {
        // alert("room " + JSON.stringify(room))
        this.sdkClient.stopVideoCall(
            room,
            participant,
            eventId,
            numberOfPArticipants
        );
    }
    stopAudioCall(room, participant, eventId, numberOfPArticipants) {
        // alert("room " + JSON.stringify(room))
        this.sdkClient.stopAudioCall(
            room,
            participant,
            eventId,
            numberOfPArticipants
        );
    }
    replyMessage(roomId, chatMessage, body) {
        // alert("room " + JSON.stringify(room))
        this.sdkClient?.replyMessage(roomId, chatMessage, body);
    }

    replyUploadFile(roomId, chatMessage, file) {
        this.sdkClient.replyMessageUploadFile(roomId, chatMessage, file);
    }

    replySendLocation(roomId, chatMessage, geo_uri, imageUrl, locationUrl) {
        alert("test", roomId, chatMessage, geo_uri, imageUrl, locationUrl);
        this.sdkClient.replyMessageSendLocation(
            roomId,
            chatMessage,
            geo_uri,
            imageUrl,
            locationUrl
        );
    }

    leaveRoomCall(roomId) {
        this.sdkClient.leaveRoom(roomId);
    }

    kickUserCall(room, user, callbackFunction) {
        this.sdkClient.kickUser(room, user, callbackFunction);
    }

    inviteUserCall(roomId, user) {
        this.sdkClient.inviteUserToRoomWithId(roomId, user);
    }

    inviteUserListCall(roomId, users) {
        this.sdkClient.inviteUserListToRoomWithId(roomId, users);
    }

    cancelAudioCall(roomId, userId) {
        this.sdkClient.cancelAudioCall(roomId, userId);
    }

    cancelVideoCall(roomId, userId) {
        this.sdkClient.cancelVideoCall(roomId, userId);
    }

    timeoutAudioCall(roomId, userId) {
        this.sdkClient.timeoutAudioCall(roomId, userId);
    }

    timeoutVideoCall(roomId, userId) {
        this.sdkClient.timeoutVideoCall(roomId, userId);
    }

    stopVideoCallEventHandler(allRooms) {
        // alert("126====="+JSON.stringify(allRooms.allRooms))
        this.props
            .syncRooms(allRooms.allRooms)
            .then((data) => {
             //   console.log("58 helloooo====", data);
                // alert("helloooo====", JSON.stringify(data));
            })
            .catch((e) => {
                alert(e);
            });
    }
    stopAudioCallEventHandler(allRooms) {
        // alert("126====="+JSON.stringify(allRooms.allRooms))
        this.props
            .syncRooms(allRooms.allRooms)
            .then((data) => {
             //   console.log("58 helloooo====", data);
                // alert("helloooo====", JSON.stringify(data));
            })
            .catch((e) => {
                alert(e);
            });
    }

    async syncComplete(allRooms) {
        // let allRooms = await this.sdkClient.getRooms();
     //   console.log("72========", allRooms);
        this.props
        .syncRooms(allRooms.allRooms)
        .then((data) => {
            this.setState({ sync: false });

             //   console.log("300=====", data);
                // alert("helloooo====", JSON.stringify(data));
            })
            .catch((e) => {
                alert(e);
            });
    }

    componentDidMount() {
        let { currentUser } = this.props;
        // alert("currentUser"+JSON.stringify(currentUser))
        
        let options = {
            user_id: currentUser.user_id,
            access_token: currentUser.access_token,
            // homeserver: "https://devm.vjoinlife.com",
            homeserver:serverUrl,
            password: "123Lahore!"
        };
        this.sdkClient = new Client(options);
        this.sdkClient.on("syncComplete", this.syncComplete);
        this.sdkClient.on("message", this.message);
        this.sdkClient.on("receipt", this.message);
        this.sdkClient.on("typing", this.typing);
        this.sdkClient.on("stopVideoCall", this.stopVideoCallEventHandler);
        this.sdkClient.on("stopAudioCall", this.stopAudioCallEventHandler);
        this.sdkClient.on("createRoom", this.createRoom);
        this.sdkClient.on("joinedRoom", this.joinedRoom);
        
        // this.sdkClient.on("stopAudioCall", this.stopAudioCallEventHandler);
    }

    handleRowClick(event) {
        alert(event);
    }
    save(row) {
        this.setState({
            selectedRow: row
        });
        alert(JSON.stringify(row));
    }
    setSelectedRow(row) {
        this.setState({
            selectedRow: row
        });
    }

    updateChildProps() {}
    render() {
        const { allRooms, currentUser } = this.props;
        // alert(JSON.stringify(this.state))
        let selectedRow = this.props.selectedRow;
        let selectedRoomId = 0;
        // const SetIncomingCallRoomID = (obj) => {
        //     let filteredArray = allRooms.filter(function (e) {
        //         return e.roomId === obj;
        //     });
        //  //   console.log(
        //         "incomingCall_incomingCall_selectedRoomId",
        //         filteredArray
        //     );
        //     selectRoom(filteredArray);
        // };
        if (selectedRow != undefined) {
            selectedRoomId = selectedRow.roomId;
        }

     //   console.log("307 this.state ", selectedRow);
        // alert("selectedRow=====", selectedRow)

     //   console.log("STATE VALUE:", this.state.expandDropdown);

        return (
            <>
            <div style={{opacity:this.state.sync&& "0",pointerEvents:this.state.sync&&"none"}}
                id={`${this.state.darkOn ? "dark" : "light"}`}
                className="desktop_container"
            >
                <ContextGlobal>
                    <div className="desktop_topbar">
                        <TopBar
                        setAvatarUrl={this.setAvatarUrl}
                        getAvatarUrl={this.getAvatarUrl}

                            expandDropdown={this.expandDropdown}
                            darkOn={this.darkOn}
                            valueDark={this.state.darkOn}
                            leftPanelHide={this.leftPanelHide}
                        />
                    </div>
                    <div className="desktop_other_component">
                        {!this.state.expandDropdown ? (
                            <div className="desktop_left_wrapper">
                                <SideBar />
                            </div>
                        ) : null}
                        {/* <div className="desktop_left_wrapper">
                                <SideBar />
                            </div> */}

                        <div style={{display:this.state.leftPanelHide?"":"none"}} className="desktop_middle_wrapper">
                            <LeftPane
                                // key={selectedRoomId}
                                rooms={allRooms}
                                currentUser={currentUser}
                                createGroupCallBack={this.createGroup.bind(
                                    this
                                )}
                                getAvatarUrl={this.getAvatarUrl}


                            />
                            
                        </div>
                        <div className="desktop_right_wrapper d-flex">
                            <RightChatWindow
                                showVideo={this.showVideo}
                                key={selectedRoomId}
                                setRoomAvatarUrl={this.setRoomAvatarUrl}
                                getRoomAvatarUrl={this.getRoomAvatarUrl}
                                //valuee={false}
                                rooms={allRooms}
                                search={this.search.bind(this.search)}
                                getNextPage={this.getNextPage.bind(this)}
                                getPreviousPage={this.getPreviousPage.bind(
                                    this
                                )}
                                selectedRow={selectedRow}
                                currentUser={currentUser}
                                sendMessageCallBack={this.sendTextMessage.bind(
                                    this
                                )}
                                cancelVideoCall={this.cancelVideoCall.bind(
                                    this
                                )}
                                sendTypingCallBack={this.setTypingIndicatorForRoom.bind(
                                    this
                                )}
                                sendLocationCallBack={this.sendLocation.bind(
                                    this
                                )}
                                sendImageCallBack={this.matrixUploadFile.bind(
                                    this
                                )}
                                sendVideoCallBack={this.matrixUploadFile.bind(
                                    this
                                )}
                                sendAudioCallBack={this.matrixUploadFile.bind(
                                    this
                                )}
                                createVideoCall={this.createVideoCall.bind(
                                    this
                                )}
                                createAudioCall={this.createAudioCall.bind(
                                    this
                                )}
                                isInitialSyncComplete={this.isInitialSyncComplete.bind(
                                    this
                                )}
                                stopAudioCall={this.stopAudioCall.bind(this)}
                                setGroupName={this.setGroupName.bind(this)}
                                setTopic={this.setTopic.bind(this)}
                                leaveRoomCallBack={this.leaveRoomCall.bind(

                                    this
                                )}
                                kickUserCallBack={this.kickUserCall.bind(this)}
                                inviteUserCallBack={this.inviteUserCall.bind(
                                    this
                                )}
                                inviteUserListCallBack={this.inviteUserListCall.bind(
                                    this
                                )}
                                replyMessageCallback={this.replyMessage.bind(
                                    this
                                )}
                                replyUploadFileCallback={this.replyUploadFile.bind(
                                    this
                                )}
                                replySendLocationCallback={this.replySendLocation.bind(
                                    this
                                )}
                                fowardMatrixUploadFile={this.fowardMatrixUploadFile.bind(
                                    this
                                )}
                                forwardLocation={this.forwardLocation.bind(
                                    this
                                )}
                                sendReaction={this.sendReaction.bind(this)}
                                setReadMarker={this.setReadMarker.bind(this)}
                            />
                            <VideoCall
                                rooms={allRooms}
                                selectedRow={selectedRow}
                                stopVideoCall={this.stopVideoCall.bind(this)}
                                createVideoCall={this.createVideoCall.bind(
                                    this
                                )}
                                createAudioCall={this.createAudioCall.bind(
                                    this
                                )}
                                stopAudioCall={this.stopAudioCall.bind(this)}
                            />
                        </div>
                    </div>
                </ContextGlobal>
            </div>
            {this.state.sync&&

            <div style={{position:"absolute",top:"50%",left:"50%",transform:"translate(-50%,-50%)"}}>
                <div >
                <img
                            src={whatsapp}
                            alt="profile"
                        />
                    
                </div>
                <div style={{marginTop:"45px"}}>
                <LinearProgress sx={{
                  backgroundColor: '#ddd',
                  '& .MuiLinearProgress-bar': {
                    backgroundColor: '#2abfd4'
                  }
                }}/>
                <div style={{marginTop:"17px"}}>
                <p style={{textAlign:"center",color:""}} >Loading your chats</p>
                </div>
                <div style={{marginTop:"7px"}}>
                <p style={{textAlign:"center",color:"darkgray",fontSize:"14px"}} > <label className="fa fa-lock"></label> End-to-end encrypted</p>
                </div>
                </div>
    
            </div>
    }
            </>
        );
    }
}
const mapStateToProps = (state) => {
 //   console.log("521=====state", state);
    //  alert("Desktop mapStateToProps ==== ", JSON.stringify(state.desktop.client))
    return {
        access_token: state.auth?.user.access_token,
        currentUser: state?.auth?.user,
        allRooms: state?.messagingSDK?.allRooms,
        selectedRow: state.desktop.selectedRow,
        time: state?.messagingSDK?.time,
        unreadMessagesMap: state?.messagingSDK?.unreadMessagesMap
    };
};
export default connect(mapStateToProps, { syncRooms, setClient })(
    withRouter(Desktop)
);
