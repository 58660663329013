export const CREATE_TUTORIAL = "CREATE_TUTORIAL";
export const RETRIEVE_TUTORIALS = "RETRIEVE_TUTORIALS";
export const UPDATE_TUTORIAL = "UPDATE_TUTORIAL";
export const DELETE_TUTORIAL = "DELETE_TUTORIAL";
export const DELETE_ALL_TUTORIALS = "DELETE_ALL_TUTORIALS";
//End TODO remove these once done start

//auth constants
export const AUTHENTICATE = "AUTHENTICATE";
export const REGISTER = "REGISTER";
export const LOGOUT = "LOGOUT";

//DESKTOP
export const FETCH_DESKTOP = "FETCH_DESKTOP";
export const FETCH_DESKTOP_ERROR = "FETCH_DESKTOP_ERROR";
export const SELECT_ROOM = "SELECT_ROOM";

// SEARCH USERS
export const SEARCH_USER_CREATE_ROOM = "SEARCH_USER_CREATE_ROOM"
export const SEARCH_USER_CREATE_ROOM_ERROR = "SEARCH_USER_CREATE_ROOM_ERROR"
export const TESTING_CASE="TESTING_CASE"
//MESSAGING_SDK
export const SYNC_ROOMS = "SYNC_ROOMS";
export const SET_CLIENT = "SET_CLIENT";

//RefTables
export const STAGES = "STAGES";
export const STATUSES = "STATUSES";

// Avatar

export function defaultAvatarUrlForString(s) {
    if (!s) return ""; // XXX: should never happen but empirically does by evidence of a rageshake
    const defaultColors = ['#ffc107', '#2f3f7d', '#ac3ba8'];
    let total = 0;
    for (let i = 0; i < s.length; ++i) {
        total += s.charCodeAt(i);
    }
    const colorIndex = total % defaultColors.length;
    // overwritten color value in custom themes
    const cssVariable = `--avatar-background-colors_${colorIndex}`;
    const cssValue = document.body.style.getPropertyValue(cssVariable);
    const color = cssValue || defaultColors[colorIndex];
    
    return color;
}
export const customAvator=(name,width,size)=>{
    if (!name) {
        console.trace("`name` argument to `customAvatar` not supplied");
        return undefined;
    }
    if (name.length < 1) {
        return undefined;
    }

    // Remove leading special characters
    if ((name[0] === '@' || name[0] === '#' || name[0] === '+') && name[1]) {
        name = name.substring(1);
    }

    // Split name by spaces and take initials
    const words = name.split(" ");
    const initials = words.length > 1 
        ? `${words[0][0]}${words[1][0]}` // First two letters of first two words
        : words[0][0];   

return(
    
    <div
    style={{backgroundColor:defaultAvatarUrlForString(name),width:width||"",height:width||""}}
                            className="img-sec me-3 custom_avator"
                          
                        >
                            <span>
                                <span
                                style={{fontSize:size||""}}
                                   className="custom_avator_name"
                                >
                {initials.toUpperCase()}
                </span>
                            </span>
                        </div>
)

}

